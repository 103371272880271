import React, { useState, useEffect } from "react";
// importing dashboard screen styles for dashboard stylings
import "./styles/DashboardScreen.css";

import homeContainerConstructionImage from "../../../assets/HomeContainerConstructionImage.png";
import dashboardArrow1 from "../../../assets/DashboardArrow1.svg";
import dashboardArrow2 from "../../../assets/DashboardArrow2.svg";
import dashboardStar from "../../../assets/DashboardStarWithFill.svg";
import Cookies from "js-cookie";
import { getSubCoursesService } from "../../../services/courses/GetSubCoursesService";
import { getCompletedLessonIDs } from "../../../services/dripFeeding/GetCompletedLessonIDs";
import { GetLessonDetailsWithSubCourseId } from "../../../services/lessons/GetLessonDetailsWithSubCourseId";
import { GetLessonDetailsWithCourseId } from "../../../services/lessons/GetLessonDetailsWithCourseId";
// importing the jwt decode for decoding the tokens details
import jwt_decode from "jwt-decode";

// using the react router dom for the navigation
import { useNavigate } from "react-router-dom";

// importing the courses list from course services
import { coursesServices } from "../../../services/courses/CoursesServices";
import { getCoursesArrayService } from "../../../services/courses/GetCoursesArrayService";

export default function DashboardScreen() {
	// declaring token variable for getting global authToken
	var token = Cookies.get("authToken");
	var user = jwt_decode(token);
	// used response for getting response from courseservice
	var [response, setResponse] = useState([]);

	// used for increamenting and decreamenting response indexes
	var [indexes, setIndexes] = useState(0);
	function removeDuplicates(arr) {
		return [...new Set(arr)];
	}
	var [percentageArray, setPercentageArray] = useState([]);
	var [percentageIArray, setPercentageIArray] = useState([]);
	useEffect(() => {
		// calling the course service for getting course list
		coursesServices().then((result) => {
			var rewampedCoursesArray = [];
			getCoursesArrayService(user.id).then((resultArray) => {
				// eslint-disable-next-line array-callback-return
				result.map((responses) => {
					if (resultArray.includes(responses.id)) {
						rewampedCoursesArray.push(responses);
					}
				});
				setResponse(rewampedCoursesArray);
				// below variable for storing percentages and indexes
				var thePercentageArray = [];
				var percentageIndexArray = [];
				// below statement maps the rewamped course array
				var promises = rewampedCoursesArray.map(
					(progressResponse, index) => {
						return new Promise((resolve) => {
							// below condition was rendered when course has subcourses
							if (progressResponse.isSubCourse) {
								getSubCoursesService(progressResponse.id).then(
									(subcourseDetails) => {
										// below variables stores completed and total video array
										var dripFeedingArray = [];
										var totalLessonsCount = [];

										Promise.all(
											// below statement traverses the whole subcourses
											subcourseDetails.map(
												async (
													mapedSubcourseDetails
												) => {
													var dripResult =
														await getCompletedLessonIDs(
															{
																studentId:
																	user.id,
																courseId: 0,
																subCourseId:
																	mapedSubcourseDetails.id,
															}
														);
													var lessonResult =
														await GetLessonDetailsWithSubCourseId(
															mapedSubcourseDetails.id
														);
													// below commands updates completed and total video array
													dripFeedingArray.push(
														removeDuplicates(
															dripResult
														)
													);
													totalLessonsCount.push(
														lessonResult.length
													);
												}
											)
										).then(() => {
											var concatenatedArray = [].concat(
												...dripFeedingArray
											);
											var lessonConcatenatedArray =
												[].concat(...totalLessonsCount);
											var sum =
												lessonConcatenatedArray.reduce(
													(a, b) => a + b
												);
											// console.log(Math.ceil(percentage));
											// below command updates the percentages and indexes
											percentageIndexArray.push(index);
											if (
												progressResponse.id === 11 ||
												progressResponse.id === 12
											) {
												// unblocking the 6videos for the ff1 course
												thePercentageArray.push(
													Math.ceil(
														((concatenatedArray.length +
															6) *
															100) /
															sum
													)
												);
											} else {
												thePercentageArray.push(
													Math.ceil(
														((concatenatedArray.length +
															2) *
															100) /
															sum
													)
												);
											}
											// var percentage =
											// 	((concatenatedArray.length +
											// 		2) *
											// 		100) /
											// 	sum;
											// // console.log(Math.ceil(percentage));
											// // below command updates the percentages and indexes
											// percentageIndexArray.push(index);
											// thePercentageArray.push(
											// 	Math.ceil(percentage)
											// );
											resolve();
										});
									}
								);
								// below condition was rendered when course doesnot has subcourses
							} else if (!progressResponse.isSubCourse) {
								// below two services helps updating percentages and indexes
								getCompletedLessonIDs({
									studentId: user.id,
									courseId: progressResponse.id,
									subCourseId: 0,
								}).then((dripResult) => {
									var completedArray =
										(removeDuplicates(dripResult).length +
											2) *
										100;
									GetLessonDetailsWithCourseId(
										progressResponse.id
									).then((result) => {
										// console.log(Math.ceil(completedArray / result.length));
										percentageIndexArray.push(index);
										if (progressResponse.id === 2) {
											// unblocking the 3videos for the jgm course
											thePercentageArray.push(
												Math.ceil(
													((removeDuplicates(
														dripResult
													).length +
														3) *
														100) /
														result.length
												)
											);
										} else if (progressResponse.id === 34) {
											// unblocking the 5videos for come india sing
											thePercentageArray.push(
												Math.ceil(
													((removeDuplicates(
														dripResult
													).length +
														5) *
														100) /
														result.length
												)
											);
										} else if (progressResponse.id === 10) {
											thePercentageArray.push(
												Math.ceil(
													(removeDuplicates(
														dripResult
													).length *
														100) /
														result.length
												)
											);
										} else {
											thePercentageArray.push(
												Math.ceil(
													completedArray /
														result.length
												)
											);
										}
										resolve();
									});
								});
							}
						});
					}
				);
				Promise.all(promises).then(() => {
					setPercentageArray(thePercentageArray);
					setPercentageIArray(percentageIndexArray);
				});
			});
		});
	}, [user.id]);
	const navigate = useNavigate();
	function increamentIndexes() {
		if (indexes >= 0 && indexes < response.length - 1) {
			setIndexes(indexes + 1);
		} else if ((indexes = response.length - 1)) {
			setIndexes(0);
		}
	}
	function decreamentIndexes() {
		if (indexes <= response.length - 1 && indexes > 0) {
			setIndexes(indexes - 1);
		} else if ((indexes = 1)) {
			setIndexes(response.length - 1);
		}
	}

	return (
		<>
			{user.coursesAllowed ? (
				<div className='DashboardScreenContainer'>
					<div className='DashboardScreenWrapper'>
						<h2>Course Activity</h2>
						<div className='DashboardScreenCourseWrapper'>
							<img
								src={dashboardArrow2}
								alt='dashboardArrow2'
								className='DashboardScreenIconWrapper'
								onClick={decreamentIndexes}
							/>
							{percentageIArray.length === 0
								? null
								: response
										.slice(indexes, indexes + 1)
										.map((responses) => {
											function cookieCourseId() {
												if (responses.isSubCourse) {
													navigate(
														"/home/subcourses",
														{
															state: {
																useGetSubCoursesWithSubCourseId: false,
																coursesId:
																	responses.id,
															},
														}
													);
												} else {
													Cookies.set(
														"useGetLessonDetailsWithSubCoursesId",
														"false"
													);
													Cookies.set(
														"coursesId",
														responses.id
													);
													Cookies.set(
														"courseDescription",
														responses.courseDescription
													);
													Cookies.set(
														"courseImage",
														responses.imgURL
													);
													Cookies.set(
														"courseTitle",
														responses.courseTitle
													);
													navigate("/home/lessons");
												}
											}
											var percentages =
												percentageArray[
													percentageIArray.indexOf(
														indexes
													)
												];
											return (
												<div
													className='DashboardScreenCourses'
													onClick={cookieCourseId}>
													<div className='DashboardScreenBookCover'>
														<img
															src={
																responses.imgURL
															}
															alt='redBookWebCover'
														/>
													</div>
													<div className='DashboardScreenDescription'>
														<div className='DashboardScreenCourseHeading'>
															<h4>
																{
																	responses.courseTitle
																}
															</h4>
															{/* <p>Enroll</p> */}
														</div>
														<p>
															Introducing you the
															musical world and
															unlocking the
															secrets of playing
															piano
														</p>
														<div className='DashboardScreenStarRatings'>
															<img
																src={
																	dashboardStar
																}
																alt='dashboardStar'
															/>
															<img
																src={
																	dashboardStar
																}
																alt='dashboardStar'
															/>
															<img
																src={
																	dashboardStar
																}
																alt='dashboardStar'
															/>
															<img
																src={
																	dashboardStar
																}
																alt='dashboardStar'
															/>
															<img
																src={
																	dashboardStar
																}
																alt='dashboardStar'
															/>
															<p>5.0</p>
														</div>
														<div className='DashboardScreenProgressBarWrapper'>
															<h6>
																{percentages +
																	"%"}
															</h6>
															<div className='DashboardScreenProgressBars'>
																<hr
																	className='DashboardScreenProgressBar1'
																	style={{
																		width:
																			percentages +
																			"%",
																	}}
																/>
															</div>
														</div>
													</div>
												</div>
											);
										})}
							<img
								src={dashboardArrow1}
								alt='dashboardArrow1'
								className='DashboardScreenIconWrapper'
								onClick={increamentIndexes}
							/>
						</div>
					</div>
					{/* <div className="DashboardScreenWrapper">
            <h2>Course Activity</h2>
            <form>
              {response.map((responses) => {
                return (
                  <input
                    type="radio"
                    name="fancy"
                    value={responses.id}
                    id={responses.id}
                  />
                );
              })}
              {response.map((responses) => {
                return (
                  <label for={responses.id}>
                    <div className="DashboardScreenCourses">
                      <div className="DashboardScreenBookCover">
                        <img src={responses.imgURL} alt="redBookWebCover" />
                      </div>
                      <div className="DashboardScreenDescription">
                        <div className="DashboardScreenCourseHeading">
                          <h4>{responses.courseTitle}</h4>
                          <p>Enroll</p>
                        </div>
                        <p>
                          Introducing you the musical world and unlocking the
                          secrets of playing piano
                        </p>
                        <div className="DashboardScreenStarRatings">
                          <img src={dashboardStar} alt="dashboardStar" />
                          <img src={dashboardStar} alt="dashboardStar" />
                          <img src={dashboardStar} alt="dashboardStar" />
                          <img src={dashboardStar} alt="dashboardStar" />
                          <img src={dashboardStar} alt="dashboardStar" />
                          <p>5.0</p>
                        </div>
                        <div className="DashboardScreenProgressBars">
                          <hr className="DashboardScreenProgressBar1" />
                          <hr className="DashboardScreenProgressBar2" />
                        </div>
                      </div>
                    </div>
                  </label>
                );
              })}
            </form>
          </div> */}
				</div>
			) : (
				<img
					src={homeContainerConstructionImage}
					alt='homeContainerConstructionImage'
					className='HomeContainerConstructionImage'
				/>
			)}
		</>
	);
}
