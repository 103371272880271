// importing the usestate from react for updating variables
import { useState, useEffect } from "react";

// importing the youtube icon for showing inside course dashboard
import profileYoutubeIcon from "../../../../../assets/profileIcons/ProfileYoutubeIcon.svg";
import { GetLessonDetailsWithSubCourseId } from "../../../../../services/lessons/GetLessonDetailsWithSubCourseId";
import { getCompletedLessonIDs } from "../../../../../services/dripFeeding/GetCompletedLessonIDs";

// importing link from react routes for routing through different pages
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

export default function CoursesCards(props) {
	const navigate = useNavigate();
	// declaring below variable for updating the specific user progress
	const [userLessonProgress, setUserLessonProgress] = useState();
	const [lessonArrayLength, setLessonArrayLength] = useState();
	function removeDuplicates(arr) {
		return [...new Set(arr)];
	}
	useEffect(() => {
		if (props.isModule === true) {
			// below code was made for getting the completed video array
			const decode = jwt_decode(Cookies.get("authToken"));
			let coursesDetails = {
				studentId: decode.id,
				courseId: 0,
				subCourseId: props.subCourseSerialNumber,
			};
			// using the below service for getting the videos array length
			GetLessonDetailsWithSubCourseId(props.subCourseSerialNumber).then(
				(lessonsArray) => {
					// using the below service for getting the unblocked videos array
					getCompletedLessonIDs(coursesDetails).then(
						(completedLessons) => {
							setLessonArrayLength(lessonsArray.length);
							if (
								// include new subcourseid when videos are manually unblocked
								props.subCourseSerialNumber === 1 ||
								props.subCourseSerialNumber === 5 ||
								props.subCourseSerialNumber === 13 ||
								props.subCourseSerialNumber === 18 ||
								props.subCourseSerialNumber === 26 ||
								props.subCourseSerialNumber === 29
								// unblocking the 2videos for the ff2 course
								// unblocking the 2videos for the ff1 course
								// unblocking the 2videos for the walking together
							) {
								// updating the below variable for showing the updated user progress
								setUserLessonProgress(
									removeDuplicates(completedLessons).length +
										2
								);
							} else if (
								props.subCourseSerialNumber === 12 ||
								props.subCourseSerialNumber === 17
							) {
								setUserLessonProgress(4);
							} else {
								// updating the below variable for showing the updated user progress
								setUserLessonProgress(
									removeDuplicates(completedLessons).length
								);
							}
						}
					);
				}
			);
		}
	}, [props.isModule, props.subCourseSerialNumber]);

	function cookieCourseId() {
		if (props.isModule === false) {
			navigate("/home/subcourses", {
				state: {
					useGetSubCoursesWithSubCourseId: true,
					subCoursesId: props.subCourseSerialNumber,
				},
			});
		} else {
			Cookies.set("useGetLessonDetailsWithSubCoursesId", "true");
			Cookies.set("subCoursesId", props.subCourseSerialNumber);
			Cookies.set("courseDescription", props.subcourseDescription);
			Cookies.set("courseImage", props.thumbnail);
			Cookies.set("courseTitle", props.title);
			navigate("/home/lessons");
		}
	}
	return (
		<div className='CoursesCardsWrapper'>
			<img
				src={props.thumbnail}
				alt='redBookWebCover'
				onClick={cookieCourseId}
			/>
			<div className='CoursesCardsDescription1'>
				<h6>{props.title}</h6>
				<h6 className='CoursesCardsContinue' onClick={cookieCourseId}>
					Continue
				</h6>
			</div>
			{props.isModule === false ||
			userLessonProgress === undefined ||
			lessonArrayLength === undefined ? null : (
				<>
					<div className='CoursesCardsProgressBars'>
						<hr
							className='CoursesCardsProgressBar1'
							style={{
								width:
									(userLessonProgress * 100) /
										lessonArrayLength +
									"%",
							}}
						/>
					</div>
					<div className='CoursesCardsDescription2'>
						<div className='CoursesCardsVideosCount'>
							<img
								src={profileYoutubeIcon}
								alt='profileYoutubeIcon'
							/>
							<h6>{lessonArrayLength} Lessons</h6>
						</div>
						<h6>
							{userLessonProgress + " / " + lessonArrayLength}
						</h6>
					</div>
				</>
			)}
		</div>
	);
}
