import axios from "axios";
import { CONFIG } from "../../config";
export const markVideoCompleted = async (lessonsDetails) => {
	try {
		const response = await axios.post(
			`${CONFIG.COURSEURL}/api/student-progress/mark-video-completed`,
			lessonsDetails
		);
		console.log(response);
		return "the next video was unlocked";
	} catch (error) {
		// returning blank array after getting server and internet error
		return "some error has occured";
	}
};
