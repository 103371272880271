// importing course thumbnail for showing inside course dashboard
// import redBookWebCover from "../../../../assets/RedBookWebCover.jpg";

// importing the youtube icon for showing inside course dashboard
import profileYoutubeIcon from "../../../../../assets/profileIcons/ProfileYoutubeIcon.svg";

// importing link from react routes for routing through different pages
import { useNavigate } from "react-router-dom";

// importing the cookies library
import Cookies from "js-cookie";

export default function CoursesCards(props) {
  const navigate = useNavigate();
  function cookieCourseId() {
    if (props.isSubCourse) {
      navigate("/home/subcourses", {
        state: {
          useGetSubCoursesWithSubCourseId: false,
          coursesId: props.serialNumber,
        },
      });
    } else {
      Cookies.set("useGetLessonDetailsWithSubCoursesId", "false");
      Cookies.set("coursesId", props.serialNumber);
      Cookies.set("courseDescription", props.courseDescription);
      Cookies.set("courseImage", props.thumbnail);
      Cookies.set("courseTitle", props.title);
      navigate("/home/lessons");
    }
  }
  return (
    <div className="CoursesCardsWrapper">
      <img
        src={props.thumbnail}
        alt="redBookWebCover"
        onClick={cookieCourseId}
      />
      <div className="CoursesCardsDescription1">
        <h6>{props.title}</h6>
        <h6 className="CoursesCardsContinue" onClick={cookieCourseId}>
          Continue
        </h6>
      </div>
      <div className="CoursesCardsProgressBars">
        <hr
          className="CoursesCardsProgressBar1"
          style={{
            width: props.percentages + "%",
          }}
        />
      </div>
      <div className="CoursesCardsDescription2">
        <div className="CoursesCardsVideosCount">
          <img src={profileYoutubeIcon} alt="profileYoutubeIcon" />
          <h6>{props.totalLessons} Lessons</h6>
        </div>
        <h6>
          {props.completedLessons} / {props.totalLessons}
        </h6>
      </div>
    </div>
  );
}
