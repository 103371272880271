"use client";
import { createContext, useReducer } from "react";

export const PopupContext = createContext();
export const popupReducer = (state, action) => {
	switch (action.type) {
		case "POPUPTRUE":
			return { popup: action.payload };
		case "POPUPFALSE":
			return { popup: null };
		default:
			return state;
	}
};

export const PopupContextProvider = ({ children }) => {
	const [state, dispatch3] = useReducer(popupReducer, {
		popup: null,
	});
	return (
		<PopupContext.Provider value={{ ...state, dispatch3 }}>
			{children}
		</PopupContext.Provider>
	);
};
