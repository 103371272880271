import axios from "axios";
import { CONFIG } from "../../config";
export const updateUserDirectPhoto = async (directPhotoFormData, userId) => {
	try {
		const response = await axios.post(
			`${CONFIG.STUDENTURL}/api/students/update-students-DPurls/` +
				userId,
			directPhotoFormData
		);
		return response.data;
	} catch (error) {
		console.log(error);
		return [];
	}
};
