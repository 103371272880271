// importing the white icon images for the sidebar components
import dashboardWhiteIcon from "../../../../assets/whiteIcons/SidebarDashboardLine.svg";
import courseScreenWhiteIcon from "../../../../assets/whiteIcons/SidebarCourseLine.svg";
import profileScreenWhiteIcon from "../../../../assets/whiteIcons/SidebarProfileLine.svg";
import zoomMeetingWhiteIcon from "../../../../assets/whiteIcons/SidebarZoomLine.svg";
import bugScreenWhiteIcon from "../../../../assets/whiteIcons/SidebarBugLine.svg";

// importing the blue icon images for the sidebar components
import dashboardBlueIcon from "../../../../assets/blueIcons/DashboardIcon.svg";
import courseScreenBlueIcon from "../../../../assets/blueIcons/CourseScreenIcon.svg";
import profileScreenBlueIcon from "../../../../assets/blueIcons/ProfileScreenIcon.svg";
import zoomMeetingBlueIcon from "../../../../assets/blueIcons/ZoomMeetingIcon.svg";
import bugScreenBlueIcon from "../../../../assets/blueIcons/BugScreenIcon.svg";

// importing the blue icon images for the sidebar components
import dashboardVioletIcon from "../../../../assets/violetIcons/SidebarDashboardLine.svg";
import courseScreenVioletIcon from "../../../../assets/violetIcons/SidebarCourseLine.svg";
import profileScreenVioletIcon from "../../../../assets/violetIcons/SidebarProfileLine.svg";
import zoomMeetingVioletIcon from "../../../../assets/violetIcons/SidebarZoomLine.svg";
import bugScreenVioletIcon from "../../../../assets/violetIcons/SidebarBugLine.svg";

export const SideBarData = [
  {
    violetIcon: dashboardVioletIcon,
    whiteIcon: dashboardWhiteIcon,
    blueIcon: dashboardBlueIcon,
    heading: "Dashboard",
    to: "/home",
  },
  {
    violetIcon: courseScreenVioletIcon,
    whiteIcon: courseScreenWhiteIcon,
    blueIcon: courseScreenBlueIcon,
    heading: "Courses",
    to: "/home/courses",
  },
  {
    violetIcon: profileScreenVioletIcon,
    whiteIcon: profileScreenWhiteIcon,
    blueIcon: profileScreenBlueIcon,
    heading: "Profile",
    to: "/home/profile",
  },
  {
    violetIcon: zoomMeetingVioletIcon,
    whiteIcon: zoomMeetingWhiteIcon,
    blueIcon: zoomMeetingBlueIcon,
    heading: "Live Classes",
    to: "/home/liveclasses",
  },
  {
    violetIcon: bugScreenVioletIcon,
    whiteIcon: bugScreenWhiteIcon,
    blueIcon: bugScreenBlueIcon,
    heading: "Report a Bug",
    to: "/home/report",
  },
];
