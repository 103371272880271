import axios from "axios";
import { CONFIG } from "../../config";
export const coursesServices = async () => {
	try {
		const response = await axios.get(`${CONFIG.COURSEURL}/api/courses/all`);
		return response.data;
	} catch (error) {
		// returning blank array after getting server and internet error
		return [];
	}
};
