/* eslint-disable jsx-a11y/img-redundant-alt */
import sideCloseIcon from "../../../../assets/SideCloseIcon.svg";
import { usePopupContext } from "../../../../hooks/UsePopupContext";
import { getQuestionService } from "../../../../services/quizSystem/GetQuestionService";
import { markVideoCompleted } from "../../../../services/dripFeeding/MarkVideoCompleted";
// import { quizScoreService } from "../../../../services/quizSystem/QuizScoreService";
import { quizScoreService } from "../../../../services/quizSystem/QuizScoreService";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import "../styles/QuizPopup.css";

export default function QuizPopup(props) {
	const { dispatch3 } = usePopupContext();
	const [questions, setQuestions] = useState([]);
	const [answers, setAnswers] = useState([]);
	const [userArray, setUserArray] = useState([]);
	const [quizName, setQuizName] = useState("");
	const [quizID, setQuizID] = useState(0);
	const [quizPanel, setQuizPanel] = useState(true);
	const [loadingPanel, setLoading] = useState(false);
	const [scorePanel, setScorePanel] = useState(false);
	const [percentage, setPercentage] = useState("");
	const [index, setIndex] = useState(0);
	const [selected, setSelected] = useState(null);
	const [answersArray, setAnswersArray] = useState([]);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(function () {
		getQuestionFunction();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	async function getQuestionFunction() {
		setQuizPanel(false);
		setLoading(true);
		await getQuestionService(props.videoID).then(function (datas) {
			setQuestions(datas.questions);
			setAnswers(datas.answers);
			setQuizName(datas.quizName);
			setQuizID(datas.quizID);
		});
		setLoading(false);
		setQuizPanel(true);
	}
	return (
		<div className='QuizPopupWrapper'>
			<div className='QuizPopupHeader'>
				<h3>{quizName}</h3>
				<img
					src={sideCloseIcon}
					alt='sideCloseIcon'
					onClick={function () {
						dispatch3({ type: "POPUPFALSE" });
					}}
				/>
			</div>
			<div>
				{quizPanel ? (
					<div className='QuizPopupQNAnswer'>
						<div className='QuizPopupQuestion'>
							<h3>
								{questions[index] === undefined
									? null
									: questions[index].quizQuestion}
							</h3>
							{questions[index] === undefined ||
							questions[index].isImage === false ||
							questions[index].imageLink === null ? null : (
								<div className='QuizPopupQuestionImage'>
									<img
										src={questions[index].imageLink}
										alt='Quiz question image'
										title='Question image'
									/>
								</div>
							)}
						</div>
						<ul>
							{questions[index] === undefined
								? null
								: answers
										.filter(function (datas) {
											return (
												datas.questionID ===
												questions[index].questionID
											);
										})
										.map(function (datas, index2) {
											return (
												<li
													key={index2}
													className={
														datas.answerID ===
														selected
															? "selectedOption"
															: null
													}
													onClick={function () {
														setSelected(
															datas.answerID
														);
													}}>
													{datas.answer}
													{datas === undefined ||
													datas.isImage === false ||
													datas.imageLink ===
														null ? null : (
														<div className='QuizPopupAnswerImage'>
															<img
																src={
																	datas.imageLink
																}
																alt='Quiz answer image'
																title='Answer image'
															/>
														</div>
													)}
												</li>
											);
										})}
						</ul>
					</div>
				) : null}
				{scorePanel ? (
					<div className='QuizPopupQNResult'>
						<div className='QuizPopupQNAnswer'>
							<h3 className='QuizPopupQNHeading'>
								Your got {percentage} score
								<br />
								{props.isDocument
									? percentage > 70
										? "Congratulations, you have unlocked the next lesson"
										: "You need to practice more for acheiving better score"
									: null}
							</h3>
						</div>
						{props.isDocument
							? answersArray.map(function (datas) {
									return (
										<div
											key={datas.questionID}
											className='QuizPopupQNAnswer'>
											<div className='QuizPopupQuestion'>
												<h3>{datas.quizQuestion}</h3>
												{datas.isImage === false ||
												datas.imageLink ===
													null ? null : (
													<div className='QuizPopupQuestionImage'>
														<img
															src={
																datas.imageLink
															}
															alt='Quiz question image'
															title='Question image'
														/>
													</div>
												)}
											</div>
											<ul>
												{datas.answers.map(function (
													datas2
												) {
													return (
														<li
															key={
																datas2.answerID
															}
															className={
																datas2.isCorrect
																	? "correctOption"
																	: datas2.isMarked
																	? "wrongOption"
																	: null
															}>
															{datas2.answer}
															{datas2.isImage ===
																false ||
															datas2.imageLink ===
																null ? null : (
																<div className='QuizPopupAnswerImage'>
																	<img
																		src={
																			datas2.imageLink
																		}
																		alt='Quiz answer image'
																		title='Answer image'
																	/>
																</div>
															)}
														</li>
													);
												})}
											</ul>
										</div>
									);
							  })
							: null}
					</div>
				) : null}
				{loadingPanel ? (
					<div className='QuizPopupQNAnswer'>
						<h3>Loading...</h3>
					</div>
				) : null}
				<div className='QuizPopupButtons'>
					{selected === null ||
					index === questions.length - 1 ? null : (
						<button
							onClick={function () {
								setUserArray([...userArray, selected]);
								if (index !== questions.length - 1) {
									setIndex(index + 1);
									setSelected(null);
								}
							}}>
							Continue
						</button>
					)}
					{selected !== null && index === questions.length - 1 ? (
						<button
							onClick={async function () {
								setUserArray([...userArray, selected]);
								setQuizPanel(false);
								setLoading(true);
								const array = userArray;
								console.log(array.push(selected));
								const decode = jwt_decode(
									Cookies.get("authToken")
								);
								await quizScoreService(
									{ userArray: array },
									quizID,
									decode.id
								).then(function (response) {
									if (response !== "error") {
										setPercentage(response.percentage);
										setAnswersArray(response.answersArray);
										if (
											props.isDocument &&
											response.percentage > 70
										) {
											if (
												parseInt(props.videoID) === 478
											) {
												const videosDetails = {
													studentId: parseInt(
														decode.id
													),
													courseId: parseInt(
														props.courseId
													),
													subCourseId: parseInt(
														props.subCoursesId
													),
													videoId: 520,
												};
												markVideoCompleted(
													videosDetails
												);
											} else {
												const videosDetails = {
													studentId: parseInt(
														decode.id
													),
													courseId: parseInt(
														props.courseId
													),
													subCourseId: parseInt(
														props.subCoursesId
													),
													videoId:
														parseInt(
															props.videoID
														) + 1,
												};
												markVideoCompleted(
													videosDetails
												);
											}
										}
									}
								});
								setLoading(false);
								setScorePanel(true);
								setIndex(index + 1);
								setSelected(null);
							}}>
							Evaluate
						</button>
					) : null}
				</div>
			</div>
		</div>
	);
}
