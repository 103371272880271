import axios from "axios";
import { CONFIG } from "../../config";
export const PostUserProfileDetails = async (profileFormData, userId) => {
	try {
		const response = await axios.post(
			`${CONFIG.STUDENTURL}/api/students/update-students-socialurls/` +
				userId,
			profileFormData
		);
		return response.data;
	} catch (error) {
		console.log(error);
		return [];
	}
};
