// left components stylesheets were present inside global auth css
// importing the logo and the welcome images from assets
import logo from "../../../../assets/BlackLogo.png";
import notFound from "../../../../assets/404.png";

export default function LeftComponent() {
  return (
    <div className="LeftComponentWrapper">
      <div className="LeftComponentLogo">
        <img src={logo} alt="logo" />
      </div>
      <div className="LeftComponentWelcome">
        <img src={notFound} alt="notFound" />
        {/* <video
          loop="true"
          muted="true"
          playsinline="true"
          preload="none"
          autoplay=""
          data-src={notFound}
          data-ll-status="loaded"
          src={notFound}
        ></video> */}
      </div>
    </div>
  );
}
