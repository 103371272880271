import axios from "axios";
import { CONFIG } from "../../config";
export const userDetailsService = async (auth_token) => {
	const config = { headers: { "auth-token": auth_token } };
	try {
		const response = await axios.get(
			`${CONFIG.AUTHURL}/api/auth/user`,
			config
		);
		return response.data;
	} catch (error) {
		console.log(error);
	}
};
