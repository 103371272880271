// importing the authentication css for global stylings
import "../styles/GlobalAuth.css";

// importing the login screen css file for the styling
// import "./styles/RightComponent.css";

// importing the components for getting input fields and buttons
import LeftComponent from "./components/LeftComponent";

// importing the auth container for wrapping out contexts
import AuthContainer from "../../global/AuthContainer";
import { useMessageContext } from "../../../hooks/UseMessageContext";

import { Link } from "react-router-dom";

export default function ForgotPassword() {
  const { dispatch2 } = useMessageContext();
  const { messageArray } = useMessageContext();
  // doing same work like handle submit but now we are loging with enter key
  const handleKeyDown = async (event) => {};
  return (
    <AuthContainer>
      <LeftComponent />
      {/* below division wraps all the login field */}
      <div className="RightComponentLoginFields">
        {/* below division wraps the headings */}
        <div className="RightComponentHeading">
          <h1>
            Forgot
            <br />
            Your Password ?
          </h1>
        </div>
        {/* below division wraps the two input  and button */}
        <div className="RightComponentInputFields">
          <input
            autoComplete="password"
            placeholder="Email"
            className="InputFieldClass"
            onKeyDown={handleKeyDown}
          />
          <button onClick={()=>{
            dispatch2({ type: "MESSAGEARRAY", payload: [...messageArray,"The Button is Under Development"] });
          }}>RESET PASSWORD</button>
          <div className="RightComponentBacktoFont">
            <p className="AuthContainerBold">Or</p>
            <Link to={"/"}>
              <p className="AuthContainerBold">Back to Sign In</p>
            </Link>
          </div>
        </div>
      </div>
    </AuthContainer>
  );
}
