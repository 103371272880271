import axios from "axios";
import { CONFIG } from "../../config";
export const SaveAnalyticsScoreService = async (scoreDetails) => {
	try {
		const response = await axios.post(
			`${CONFIG.ANALYTICSURL}/analytics/save`,
			scoreDetails
		);
		console.log(response);
		return "successfully saved the score";
	} catch (error) {
		return "some error has occured";
	}
};
