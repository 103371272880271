export default function ProfileDescription(props) {
  return (
    <div className="ProfileScreenDescription">
      <h3>My profile</h3>
      <p>
        {props.description}
        {/* Hi there! It looks like you have not added a bio to your profile yet. No
        worries, you can take this opportunity to let others know more about
        your love of music and what you are interested in. Share a bit about
        your favorite genres, artists, or any fun facts about music that make
        you unique. Your bio is a great way to connect with other music lovers
        and spark interesting conversations for the lifetime. Take your time to
        customize it and make it your own! If you need any inspiration or
        guidance, feel free to ask. Let us make your bio stand out and showcase
        the amazing music fan and performer you are! */}
      </p>
    </div>
  );
}
