// importing the use state for updating the variables
import { useState } from "react";

// importing the authentication css for global stylings
import "../styles/GlobalAuth.css";

// importing the components for getting input fields and buttons
import LeftComponent from "./components/LeftComponent";

// importing use auth context for resolving redirecting issue
import { useAuthContext } from "../../../hooks/UseAuthContext";
import { useMessageContext } from "../../../hooks/UseMessageContext";

// importing the login services for giving request for login
import { LoginServices } from "../../../services/auth/LoginServices";

// using react router dom for routing from one screen to other
import { useNavigate, Link } from "react-router-dom";

// using the cookies for saving the global web variables
import Cookies from "js-cookie";

// importing the token decode library for decoding the global tokens
import decode from "jwt-decode";

// importing the auth container for wrapping out contexts
import AuthContainer from "../../global/AuthContainer";

export default function LoginScreen() {
  const { dispatch2 } = useMessageContext();
  const { messageArray } = useMessageContext();
  // declaring email for updating and getting email values
  const [email, setEmail] = useState();

  // declaring the password for getting and updating passwords
  const [password, setPassword] = useState();

  // declaring the email placeholder for giving the error message
  const [emailPlaceholder, setEmailPlaceholder] = useState("");

  // declaring the password placeholder for giving the error message
  const [passPlaceholder, setPassPlaceholder] = useState("");
  const [keepMeLoggedIn, setKeepMeLoggedIn] = useState(false);

  // declaring the isvalid boolean variable for changing placeholder
  const [isValid, setIsValid] = useState(true);

  // declaring dispatch that has already been made
  const { dispatch } = useAuthContext();

  // declaring the navigate for navigating the path
  const navigate = useNavigate();

  // keeping the cookies for setting global islogin variable
  Cookies.set("isLogin", false);

  // making the handle submit function for calling the login service
  const handleSubmit = async (event) => {
    // checks whether the email containing the following value type
    if (
      email !== undefined &&
      email.match(/^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
    ) {
      // declaring variable for keeping json data with email and password
      const userData = { email: email, password: password };

      // declaring data that keeps login service response
      const data = await LoginServices(userData);
      // console.log(decode(data.authToken).isSuspended);
      // checks whether the response gives successful message
      if (data.msg === "Successful" && !decode(data.authToken).isSuspended) {
        // setting the auth token globally for getting token values
        Cookies.set("authToken", data.authToken);
        if (keepMeLoggedIn) {
          const currentTime = new Date().getTime();
          const expirationTime = currentTime + 7 * 24 * 60 * 60 * 1000;
          localStorage.setItem("expirationTime", expirationTime);
          localStorage.setItem("authToken", data.authToken);
        }

        // dispatching the login type and payload values
        dispatch({ type: "LOGIN", payload: data.authToken });

        // now navigating into the home path after the signin
        navigate("/home");
      } else {
        setIsValid(false);
        // checking whether we have the not null field values
        if (email !== "" && password !== "") {
          // now we showing the error message to the users
          setPassPlaceholder("Incorrect password. Please try again.");
          setEmailPlaceholder("Please enter valid email address.");
          dispatch2({ type: "MESSAGEARRAY", payload: [...messageArray,"Enter valid Email and Password"] });
        } else {
          // checking whether we have the null field values
          if (email === "" && password === "") {
            // giving the solution for resolving the error
            setPassPlaceholder("You forgot to enter your password");
            setEmailPlaceholder("You forgot to enter your email address");
            dispatch2({ type: "MESSAGEARRAY", payload: [...messageArray,"Not entered Email and Password"] });
          } else if (password === "" && email !== "") {
            // giving the solution for resolving the error
            setPassPlaceholder("You forgot to enter your password");
            dispatch2({ type: "MESSAGEARRAY", payload: [...messageArray,"Please enter your Password"] });
          } else {
            // giving the solution for resolving the error
            setEmailPlaceholder("You forgot to enter your email address");
            dispatch2({ type: "MESSAGEARRAY", payload: [...messageArray,"Please enter your Email Address"] });
          }
        }
        // again setting the email and the password values null
        setEmail("");
        setPassword("");
      }
      // now the function is returning the response message
      return data.msg;
    } else {
      const userData = { userName: email, password: password };
      const data = await LoginServices(userData);
      // console.log(decode(data.authToken).isSuspended);
      if (data.msg === "Successful" && !decode(data.authToken).isSuspended) {
        Cookies.set("authToken", data.authToken);

        if (keepMeLoggedIn) {
          const currentTime = new Date().getTime();
          const expirationTime = currentTime + 7 * 24 * 60 * 60 * 1000;
          localStorage.setItem("expirationTime", expirationTime);
          localStorage.setItem("authToken", data.authToken);
        }

        dispatch({ type: "LOGIN", payload: data.authToken });
        navigate("/home");
      } else {
        setIsValid(false);
        // checking whether we have the not null field values
        if (email !== "" && password !== "") {
          // now we showing the error message to the users
          setPassPlaceholder("Incorrect password. Please try again.");
          setEmailPlaceholder("Please enter valid email address.");
          dispatch2({ type: "MESSAGEARRAY", payload: [...messageArray,"Enter valid Email and Password"] });
        } else {
          // checking whether we have the null field values
          if (email === "" && password === "") {
            // giving the solution for resolving the error
            setPassPlaceholder("You forgot to enter your password");
            setEmailPlaceholder("You forgot to enter your email address");
            dispatch2({ type: "MESSAGEARRAY", payload: [...messageArray,"Not entered Email and Password"] });
          } else if (password === "" && email !== "") {
            // giving the solution for resolving the error
            setPassPlaceholder("You forgot to enter your password");
            dispatch2({ type: "MESSAGEARRAY", payload: [...messageArray,"Please enter your Password"] });
          } else {
            // giving the solution for resolving the error
            setEmailPlaceholder("You forgot to enter your email address");
            dispatch2({ type: "MESSAGEARRAY", payload: [...messageArray,"Please enter your Email Address"] });
          }
        }
        // again setting the email and the password values null
        setEmail("");
        setPassword("");
      }
      return data.msg;
    }
  };
  // doing same work like handle submit but now we are loging with enter key
  const handleKeyDown = async (event) => {
    if (event.keyCode === 13) {
      if (
        email !== undefined &&
        email.match(/^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
      ) {
        // declaring variable for keeping json data with email and password
        const userData = { email: email, password: password };

        // declaring data that keeps login service response
        const data = await LoginServices(userData);
        // console.log(decode(data.authToken).isSuspended);
        // checks whether the response gives successful message
        if (data.msg === "Successful" && !decode(data.authToken).isSuspended) {
          // setting the auth token globally for getting token values
          Cookies.set("authToken", data.authToken);

          if (keepMeLoggedIn) {
            const currentTime = new Date().getTime();
            const expirationTime = currentTime + 7 * 24 * 60 * 60 * 1000;
            localStorage.setItem("expirationTime", expirationTime);
            localStorage.setItem("authToken", data.authToken);
          }

          // dispatching the login type and payload values
          dispatch({ type: "LOGIN", payload: data.authToken });

          // now navigating into the home path after the signin
          navigate("/home");
        } else {
          setIsValid(false);
          // checking whether we have the not null field values
          if (email !== "" && password !== "") {
            // now we showing the error message to the users
            setPassPlaceholder("Incorrect password. Please try again.");
            setEmailPlaceholder("Please enter valid email address.");
            dispatch2({ type: "MESSAGEARRAY", payload: [...messageArray,"Enter valid Email and Password"] });
          } else {
            // checking whether we have the null field values
            if (email === "" && password === "") {
              // giving the solution for resolving the error
              setPassPlaceholder("You forgot to enter your password");
              setEmailPlaceholder("You forgot to enter your email address");
              dispatch2({ type: "MESSAGEARRAY", payload: [...messageArray,"Not entered Email and Password"] });
            } else if (password === "" && email !== "") {
              // giving the solution for resolving the error
              setPassPlaceholder("You forgot to enter your password");
              dispatch2({ type: "MESSAGEARRAY", payload: [...messageArray,"Please enter your Password"] });
            } else {
              // giving the solution for resolving the error
              setEmailPlaceholder("You forgot to enter your email address");
              dispatch2({ type: "MESSAGEARRAY", payload: [...messageArray,"Please enter your Email Address"] });
            }
          }
          // again setting the email and the password values null
          setEmail("");
          setPassword("");
        }
        // now the function is returning the response message
        return data.msg;
      } else {
        const userData = { userName: email, password: password };
        const data = await LoginServices(userData);
        // console.log(decode(data.authToken).isSuspended);
        if (data.msg === "Successful" && !decode(data.authToken).isSuspended) {
          Cookies.set("authToken", data.authToken);

          if (keepMeLoggedIn) {
            const currentTime = new Date().getTime();
            const expirationTime = currentTime + 7 * 24 * 60 * 60 * 1000;
            localStorage.setItem("expirationTime", expirationTime);
            localStorage.setItem("authToken", data.authToken);
          }

          dispatch({ type: "LOGIN", payload: data.authToken });
          navigate("/home");
        } else {
          setIsValid(false);
          // checking whether we have the not null field values
          if (email !== "" && password !== "") {
            // now we showing the error message to the users
            setPassPlaceholder("Incorrect password. Please try again.");
            setEmailPlaceholder("Please enter valid email address.");
            dispatch2({ type: "MESSAGEARRAY", payload: [...messageArray,"Enter valid Email and Password"] });
          } else {
            // checking whether we have the null field values
            if (email === "" && password === "") {
              // giving the solution for resolving the error
              setPassPlaceholder("You forgot to enter your password");
              setEmailPlaceholder("You forgot to enter your email address");
              dispatch2({ type: "MESSAGEARRAY", payload: [...messageArray,"Not entered Email and Password"] });
            } else if (password === "" && email !== "") {
              // giving the solution for resolving the error
              setPassPlaceholder("You forgot to enter your password");
              dispatch2({ type: "MESSAGEARRAY", payload: [...messageArray,"Please enter your Password"] });
            } else {
              // giving the solution for resolving the error
              setEmailPlaceholder("You forgot to enter your email address");
              dispatch2({ type: "MESSAGEARRAY", payload: [...messageArray,"Please enter your Email Address"] });
            }
          }
          // again setting the email and the password values null
          setEmail("");
          setPassword("");
        }
        return data.msg;
      }
    }
  };
  return (
    <AuthContainer>
      <LeftComponent />
      {/* below division wraps all the login field */}
      <div className="RightComponentLoginFields">
        {/* below division wraps the headings */}
        <div className="RightComponentHeading">
          <h1>Login</h1>
          <p className="AuthContainerBold">Please Login to Continue!</p>
        </div>
        {/* below division wraps the two input fields */}
        <div className="RightComponentInputFields">
          <input
            autoComplete="password"
            className={isValid ? "InputFieldClass" : "invalid"}
            placeholder={isValid ? "Username or Email" : emailPlaceholder}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            autoComplete="password"
            type="password"
            className={isValid ? "InputFieldClass" : "invalid"}
            placeholder={isValid ? "Password" : passPlaceholder}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          {/* below division wraps the checkbox and forgot password */}
          <div className="RightComponentForgetPassWrapper">
            <span className="RightComponentCheckbox">
              <input
                type="checkbox"
                value={keepMeLoggedIn}
                onChange={(e) => setKeepMeLoggedIn(e.target.checked)}
              />
              <p className="AuthContainerBold">Keep me Logged In</p>
            </span>
            <Link to={"/forgot"}>
              <span className="RightComponentForgetPassword">
                <p className="AuthContainerBold">Forget Password?</p>
              </span>
            </Link>
          </div>
          <button
            onClick={() => {
              handleSubmit();
            }}
          >
            LOGIN!
          </button>
        </div>
      </div>
    </AuthContainer>
  );
}
