import { useState } from "react";
// importing audio evaluation stylings for screen styles
import "../styles/GlobalEvaluation.css";
import { CONFIG } from "../../../../config";
// importing the cookies library
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import axios from "axios";

// importing back space arrow from assets for showing back arrow
import backspaceArrow from "../../../../assets/BackspaceArrow.svg";

// importing the lesson card thumbnails for showing lesson thumbnails
// import lessonsCardsThumbnails from "../../../../assets/LessonsCardsThumbnails.webp";

// importing link from react router for routing between screens
import { Link, useLocation } from "react-router-dom";
import EvaluationResultCard from "../components/EvaluationResultCard";

export default function AudioEvaluation() {
	const location = useLocation();
	const token = Cookies.get("authToken");
	// const coursesId = Cookies.get("coursesId");
	const decode = jwt_decode(token);
	const [response, setResponse] = useState(null);
	const [loading, setLoading] = useState(false);
	const [selectedFile, setSelectedFile] = useState(null);
	const handleFileChange = (event) => {
		setSelectedFile(event.target.files[0]);
	};

	const uploadAudio = () => {
		if (selectedFile) {
			setLoading(true);
			const formData = new FormData();
			console.log(selectedFile);
			formData.append("user-audio-file", selectedFile);
			formData.append("user-id", decode.id);
			formData.append(
				"piece-id",
				location.state === null ? null : location.state.pieceId
			);
			// formData.append("course-id", coursesId);
			axios
				.post(CONFIG.EVALUATIONURL, formData)
				.then((response) => {
					console.log("file uploaded successfully", response.data);
					setResponse(response.data);
				})
				.catch((error) => {
					console.error("error uploading file", error);
					setResponse(null);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};
	return (
		<div className='HomeContextContainer'>
			<div className='GlobalEvaluationWrapper'>
				<div className='GlobalEvaluationButtonHeadWrapper'>
					<div className='GlobalEvaluationHeading'>
						<Link to='/home/lessons'>
							<img src={backspaceArrow} alt='backspaceArrow' />
						</Link>
						<h2>Audio Evaluation</h2>
					</div>
					<div className='GlobalEvaluationButtons'>
						<button className='GlobalEvaluationButton1'>
							{selectedFile === null
								? "Upload audio for evaluation"
								: selectedFile.name}
							<input
								type='file'
								onChange={handleFileChange}
								className='GlobalEvaluationFileInput'
							/>
						</button>
						<button
							className='GlobalEvaluationButton2'
							onClick={uploadAudio}>
							Upload Audio
						</button>
					</div>
				</div>
				<div className='GlobalEvaluationThumbnailSpace'>
					<img
						src={
							location.state === null
								? null
								: location.state.thumbnail
						}
						alt='lessonsCardsThumbnails'
					/>
				</div>
			</div>
			{loading === true ? (
				<h3>Please wait while we process your Audio</h3>
			) : null}
			{/* this component makes the result cards */}
			{response !== null ? (
				<div id='evaluation-result-wrapper'>
					<EvaluationResultCard
						title='Your score has been Evaluated!'
						notesPlayed={response.extracted_notes}
						actualNotes={response.master_notes}
						accuracy={response.audio_score}
						pieceId={
							location.state === null
								? null
								: location.state.pieceId
						}
						studentID={
							location.state === null
								? null
								: location.state.studentID
						}
						courseLastLessonID={
							location.state === null
								? null
								: location.state.courseLastLessonID
						}
						courseId={
							location.state === null
								? null
								: location.state.courseId
						}
						subCoursesId={
							location.state === null
								? null
								: location.state.subCoursesId
						}
					/>
				</div>
			) : null}
		</div>
	);
}
