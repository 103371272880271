import axios from "axios";
import { CONFIG } from "../../config";
export const lessonsURLServices = async (videoTitle) => {
	try {
		const formData = new FormData();
		formData.append("videoTitle", videoTitle);
		const response = await axios.post(
			`${CONFIG.COURSEURL}/api/videos/fetch-url/`,
			formData
		);
		return response.data;
	} catch (error) {}
};
