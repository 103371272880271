// importing the css for the global stylings
import "./App.css";
import { useEffect } from "react";
// importing the react router dom for making routes
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

// importing the login screen from the screens folder
import LoginScreen from "./screens/auth/loginScreen/LoginScreen";

// importing the forgot password from the screens folder
import ForgotPassword from "./screens/auth/forgotPassword/ForgotPassword";
import RecoverPassword from "./screens/auth/recoverPassword/RecoverPassword";
import PageNotFound from "./screens/auth/pageNotFound/PageNotFound";

import Home from "./screens/home/Home";

// importing use auth context for resolving redirecting issue
import { useAuthContext } from "./hooks/UseAuthContext";
import { useMessageContext } from "./hooks/UseMessageContext";

export default function App() {
	const { user } = useAuthContext();
	const { dispatch2 } = useMessageContext();
	const { messageArray } = useMessageContext();

	useEffect(() => {
		if (messageArray.length !== 0) {
			const intervalCall = setInterval(() => {
				dispatch2({
					type: "MESSAGEARRAY",
					payload: messageArray.filter((item, index) => index !== 0),
				});
			}, 3000);
			return () => {
				clearInterval(intervalCall);
			};
		}
	});
	return (
		<div className='GlobalWindowWrapper'>
			<div className='GlobalHomeMessageArrayWrapper'>
				{messageArray.map((item) => {
					return (
						<p
							className={
								item.includes("Success") ||
								item.includes("A.I.")
									? "GlobalHomeSuccessMessageArray"
									: "GlobalHomeErrorMessageArray"
							}>
							{item}
						</p>
					);
				})}
			</div>
			<BrowserRouter>
				<Routes>
					<Route path='*' element={<PageNotFound />} />
					<Route
						path='/'
						element={
							!user ? <LoginScreen /> : <Navigate to='/home/' />
						}
					/>
					<Route
						path='/login'
						element={
							!user ? <LoginScreen /> : <Navigate to='/home/' />
						}
					/>
					<Route
						path='/forgot'
						element={
							!user ? (
								<ForgotPassword />
							) : (
								<Navigate to='/home/' />
							)
						}
					/>
					<Route
						path='/recover/*'
						element={
							!user ? (
								<RecoverPassword />
							) : (
								<Navigate to='/home/' />
							)
						}
					/>
					<Route
						path='/home/*'
						element={user ? <Home /> : <Navigate to='/login' />}
					/>
				</Routes>
			</BrowserRouter>
		</div>
	);
}
