import axios from "axios";
import { CONFIG } from "../../config";
export const quizScoreService = async (userArray, quizID, studentID) => {
	try {
		const response = await axios.post(
			`${CONFIG.COURSEURL}/api/quiz-answer/` + quizID + "/" + studentID,
			userArray
		);
		return response.data;
	} catch (error) {
		return "error";
	}
};
