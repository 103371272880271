import axios from "axios";
import { CONFIG } from "../../config";
export const UpdateUserDescription = async (descriptionFormData, userId) => {
	try {
		const response = await axios.post(
			`${CONFIG.STUDENTURL}/api/students/update-students-decription/` +
				userId,
			descriptionFormData
		);
		return response.data;
	} catch (error) {
		console.log(error);
		return [];
	}
};
