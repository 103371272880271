import { createContext, useReducer } from "react";

export const MessageContext = createContext();
export const authReducer = (state, action) => {
  switch (action.type) {
    case "MESSAGEARRAY":
      return { messageArray: action.payload };
    default:
      return state;
  }
};

export const MessageContextProvider = ({ children }) => {
  const [state, dispatch2] = useReducer(authReducer, {
    messageArray: [],
  });
  // console.log("messageContext state:", state);
  return (
    <MessageContext.Provider value={{ ...state, dispatch2 }}>
      {children}
    </MessageContext.Provider>
  );
};
